
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y:scroll;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button, button:focus, button:active{
  border: none;
  outline: none;

}
a{
  text-decoration: none;
  outline: none;
}
*:active, *:focus{
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
}
p {
  color: #777;
  padding: 0;
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
input, select {
  border: none;
}
